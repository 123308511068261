.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* HOME */
body {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}
#root {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(320deg, rgba(26,58,93,1) 50%, rgba(0,0,0,1) 100%);
    color:#fff;
}
.app {
    padding: 5% 0;
    background-color: rgba(255,255,255,0.03);
}
#timersWrap{
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
#estimatedRemainingWrap,
#runTimeClockWrap,
#cadenceTrackWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.numbers {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
#estimatedRemainingDisplay{
    text-align:center;
}
#estimatedRemainingDisplay .componentLabel {
    color: #01acd1;
    text-transform:uppercase;
    font-size:40px;
    font-weight:bold;
}
#estimatedRemainingDisplay .componentTimer {
    color: #01acd1;
    font-size: 140px;
    font-weight: bold;
    line-height: 100px;
}
.componentTimer,
.componentLabel{
    color:#fff;
    font-size:35px;
    text-transform:uppercase;
    text-align:right;
}

/* CONTROLLER */
.connect-button{
    width:96%;
    margin:0 1.5%;
}
.start-button,
.pause-button,
.reset-button{
    width:30%;
    margin:20px 1.5%;
}

@media (max-width:768px){
    #estimatedRemainingDisplay .componentLabel {
        font-size: 1.2em;
    }
    #estimatedRemainingDisplay .componentTimer {
        font-size: 5.5em;
    }
    .componentTimer,
    #runTimeClockWrap .componentLabel,
    #runTimeClockWrap .runTimeDisplay,
    #cadenceTrackWrap .componentLabel,
    #cadenceDisplay{
        font-size:1.2em !important;
    }
    .componentLabel{
        font-size: 20px;
    }
}